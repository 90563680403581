/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // display: `flex`,
  // flexDirection: `column`,
  // justifyContent: `start`,
  // alignItems: `center`,
  // minHeight: `100vh`,

  return (
    <div className="global-wrapper">
      <Header        
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />

      <main
        className="globa-main-wrapper"
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        
        {children}
      </main>

      <footer
        style={{
          marginTop: `2rem`,
        }}
      >
        <div className="copyright-wrapper">
          © {new Date().getFullYear()}, All Right Reserved
        </div>
        {` `}
        {/* <a href="https://www.gatsbyjs.com">Gatsby</a> */}
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
