import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
          description
        }
        wordpressWpApiMenusMenusItems(name: { eq: "Default-Menu" }) {
          id
          wordpress_id
          slug
          name
          items {
            title
            url
            object_slug
            object_id
            object
            description
          }
        }
      }
    `}
    render={data => (
      <header
        className="global-header-wrapper"
        style={
          {
            // background: `rebeccapurple`,
            // marginBottom: `1.45rem`,
            // width: `100%`,
          }
        }
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
          }}
        >
          {/* site-title */}
          <h1 style={{ margin: 0 }}>
            {/* <Link
              to="/"
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >              
              {data.wordpressSiteMetadata.name}
            </Link> */}
          </h1>
          <div className="logo-wrapper">
            <Link
              to="/"
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >
              {/* site-Logo */}
              <img
                src="https://d1guk8oyw4i9xx.cloudfront.net/wp-content/uploads/2021/01/27134156/Logo-640-x-450-iPhone-5.png"
                className="logo-img"
                alt="丹尼爾 - 6666 Web Design"
              />
            </Link>
          </div>

          {/* site-description */}
          {/* <p style={{ marginTop: `1em`, color: `black` }}>
            {data.wordpressSiteMetadata.description}
          </p> */}

          {/* site-menu */}
          <div className="nav-menu-wrapper">
            <ul>
              {data.wordpressWpApiMenusMenusItems.items.map((item, index) => (
                <li key={item.object_slug}>
                  <div>
                    {/* total: {data.wordpressWpApiMenusMenusItems.items.length}
                    :
                    {item.object}
                    :
                    {index} */}

                    {item.object === "custom" && (
                      <Link
                        to={`${item.url}`}
                        style={{
                          color: `black`,
                          textDecoration: `none`,
                          fontFamily: `sans-serif`,
                        }}
                      >
                        {item.title}
                      </Link>
                    )}

                    {item.object === "page" && (
                      <Link
                        to={`/${item.object_slug}`}
                        style={{
                          color: `black`,
                          textDecoration: `none`,
                          fontFamily: `sans-serif`,
                        }}
                      >
                        {item.title}
                      </Link>
                    )}

                    {item.object === "post" && (
                      <Link
                        to={`/post/${item.object_slug}`}
                        style={{
                          color: `black`,
                          textDecoration: `none`,
                          fontFamily: `sans-serif`,
                        }}
                      >
                        {item.title}
                      </Link>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
